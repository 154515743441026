import queryString from 'query-string'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosLogOut } from 'react-icons/io'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { clearLoginState } from '../../app/features/auth'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { changeLanguage, selectLanguage } from '../../app/slice'
import { authStore } from '../../app/use-selector'
import arrow_menu from '../../assets/icons/arrow-menu.png'
import ic_arrow_select_lc from '../../assets/icons/ic_arrow_select_lc.png'
import ic_cart from '../../assets/icons/ic_cart.svg'
import ic_location_top from '../../assets/icons/ic_location_top.png'
import ic_menu from '../../assets/icons/ic_menu.svg'
import ic_order from '../../assets/icons/ic_order.svg'
import ic_search from '../../assets/icons/ic_search.svg'
// import ic_service from '../../assets/icons/ic_service.svg'
import ic_user from '../../assets/icons/ic_user.svg'
// import icon_contact from '../../assets/icons/icon_contact.svg'
import { BsSearch } from 'react-icons/bs'
// import { useGetHomeSettingQuery } from '../../app/features/home'
import {
  useGetSuggestSearchQuery,
  useHomeSearchHistoryQuery,
  useHomeSearchMutation,
} from '../../app/features/homeSearch'
import { useGetVoucherListQuery } from '../../app/features/voucher'
import logo_header from '../../assets/images/logo_header.png'
import logo_headerJA from '../../assets/images/logo_header.png'
import '../../assets/scss/header.scss'
import ModalAuth from '../../components/Auth/ModalAuth'
import { selectCartData } from '../../components/fastCart/store/slice'
import {
  DIABETES_KNOWLEDGE_LINK,
  DISTRIBUTOR_LINK,
  KEY_AUTH,
  LanguageList,
  ListDropdownUser,
  languageJA,
} from '../../constant'
import { getToken, removeToken } from '../../helpers/cookieHelper'
import { debounce } from '../../helpers/ultis'
import { HomeSearchDto } from '../../models/homeSearch/homeSearchType'
import { ROUTER } from '../../router/config'
import Menu from './Menu'
import { POST_PAGE_SIZE, PRODUCT_PAGE_SIZE } from './constant'
import { selectHomeStore } from '../../pages/home/store/slice'
import { getHomeSetting } from '../../app/features/home/home-api'
import { getLang } from '../../i18n'
import { useCountUnreadTotalMutation } from '../../app/features/userNotify'
import { changeUnreadTotal, selectUnreadTotal } from '../../pages/userNotification/store/slice'
// import { formatPriceVND } from '../../helpers/formatPrice'
type SearchType = {
  name: string
  slug: string
}
export function Header() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const accessToken = getToken(KEY_AUTH)
  const [focus, setFocus] = useState<boolean>(false)
  const selectRef: any = useRef(null)
  const auth = useAppSelector(authStore).user
  const language = useAppSelector(selectLanguage)
  const [searchParams] = useSearchParams()
  const [params, setParams] = useState({
    keyword: '',
  })
  const [searchText, setSearchText] = useState('')
  const [result, setResult] = useState<SearchType[]>([])
  // const { data: homeSettings } = useGetHomeSettingQuery({})
  const homeSettings = useAppSelector(selectHomeStore)
  const [homeSearch, { data: searchData, isLoading }] = useHomeSearchMutation()
  const { data: suggestData } = useGetSuggestSearchQuery({})
  const { data: historySearchList } = useHomeSearchHistoryQuery({})
  const topSearchList = suggestData ? suggestData.popular_keywords : []
  const banner = suggestData ? suggestData.banners : []
  const popularSearchList = suggestData ? suggestData.popular_products : []
  const featuredCategoriesList = suggestData ? suggestData.feature_categories : []
  const { data: voucherData } = useGetVoucherListQuery({})
  const [countUnreadTotal] = useCountUnreadTotalMutation()
  const unreadTotalNumber = useAppSelector(selectUnreadTotal) ?? ''

  const languageList = LanguageList
  const listDropdownUser = ListDropdownUser
  listDropdownUser[0].text = auth?.points?.toString() || ''
  listDropdownUser[1].text = voucherData ? Object.keys(voucherData)?.length?.toString() : ''
  listDropdownUser[4].text = unreadTotalNumber.toString() || ''

  const cartData = useAppSelector(selectCartData)

  // const orderNumber = 10
  const cartNumber = cartData?.items
    ? cartData.items.reduce((total: any, item: any) => {
        return item.quantity + total
      }, 0)
    : 0
  // const price = formatPriceVND(cartData?.amount)
  // const userAddress = 'Phường Mỹ Đình 2, Quận Nam Từ Liêm, Thành phố Hà Nội'

  // End Sample

  const langItem = languageList.find((item) => item.id === language) || languageList[0]

  const onFocus = () => {
    setFocus(true)
  }

  const handleClick = (e: any) => {
    if (!selectRef?.current?.contains(e.target)) {
      setFocus(false)
    }
  }

  const handleChangeLang = (id: string) => {
    dispatch(changeLanguage(id))
    window.location.reload()
  }

  const handleSearch = (key?: string) => {
    const keyw = key ? key : searchText
    if (keyw) setSearchText(keyw)
    // setSearchParams({ ...params, keyword: keyw })
    setFocus(false)
    setParams((prev) => ({ ...prev, keyword: keyw }))
    //SetParam
    navigate(ROUTER.SEARCH_SCREEN + '?keyword=' + keyw)
  }

  const onchangeParams = (key: string, value: any) => {
    setParams((prev) => ({ ...prev, [key]: value }))

    if (key?.length > 0) {
      const dto: HomeSearchDto = {
        keyword: value,
        productPageSize: PRODUCT_PAGE_SIZE,
        postPageSize: POST_PAGE_SIZE,
        postPage: 1,
        productPage: 1,
        // sortProduct: {
        //   price: 'desc',
        // },
        is_save: 0,
      }
      homeSearch(dto)
    } else {
      const historyList = historySearchList
        ? historySearchList.map((item) => ({ name: item.keyword, slug: item.keyword }))
        : []
      setResult(historyList)
    }
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  // const handleClickItem = (item: CategoryType) => {
  //   //Chuyển trang
  //   navigate(ROUTER.CATEGORY + '/' + item.slug)
  // }

  useEffect(() => {
    dispatch(getHomeSetting())
    countUnreadTotal({}).then((res: any) => {
      dispatch(changeUnreadTotal(res?.data?.unreadTotal))
    })
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  useEffect(() => {
    const paramsUrl = queryString.parse(window.location.search) as any
    setSearchText(paramsUrl.keyword ?? '')
    setParams(paramsUrl)
  }, [searchParams])

  useEffect(() => {
    const searchProduct = searchData ? searchData.products.data : []
    const searchProductShow = searchProduct.map((item) => ({ name: item.name, slug: item.slug }))
    const searchPost = searchData ? searchData.posts.data : []
    const searchPostShow = searchPost.map((item) => ({ name: item.title, slug: item.slug }))

    const searchShow = [...searchProductShow, ...searchPostShow]
    setResult(searchShow)
  }, [searchData])

  const renderMenuCategory = () => {
    return (
      <div className='header-menu header-menu-category'>
        <img src={ic_menu} alt={t('header.omiPharmaLogo')} />
        <div className='header-menu-content'>
          {langItem.id === languageJA ? (
            <p>
              {t('header.product')}
              <span>{t('header.category')}</span>
            </p>
          ) : (
            <p>
              {t('header.category')}
              <span>{t('header.product')}</span>
            </p>
          )}
        </div>
        {homeSettings?.menu?.length > 0 && (
          <div className='header-dropdown-category-wrap'>
            <div className='header-dropdown-category'>
              <div className='banner__category-product'>
                <ul className='category-product__menu__ul'>
                  {homeSettings?.menu?.map((item, index) => {
                    return (
                      <li key={index} className='category-product__menu__li'>
                        <Link to={ROUTER.CATEGORY + '/' + item.slug}>
                          <div className='category-product__item'>
                            <img className='category-product__icon' src={item.icon_web} alt='' />
                            <p>{item.name}</p>
                            <p className='slick-arrow'>
                              <img src={arrow_menu} alt='' />
                            </p>
                          </div>
                        </Link>
                        {item?.children && item.children.length > 0 && (
                          <div className='category-product__menu__li__sub'>
                            <ul>
                              {item.children.map((subItem, index) => {
                                return (
                                  <li key={index}>
                                    <Link to={ROUTER.CATEGORY + '/' + subItem.slug}>
                                      <img src={subItem.thumbnail} alt='' />
                                      <p>{subItem.name}</p>
                                    </Link>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  // const renderMenuService = () => {
  //   return (
  //     <div className='header-menu header-menu-service'>
  //       <img src={ic_service} alt={''} />
  //       <div className='header-menu-content'>
  //         <span>{t('header.service')}</span>
  //       </div>
  //       <div className='header-dropdown-service-wrap'>
  //         <div className='header-dropdown-service'>
  //           {service.map((service, index) => {
  //             return (
  //               <Link key={index} to={service.link}>
  //                 <div className='header-dropdown-service__item'>
  //                   <img src={service.src} alt={service.alt} />
  //                   <p>{t(service.name)}</p>
  //                 </div>
  //               </Link>
  //             )
  //           })}
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  const logout = () => {
    // if (window.confirm(t('logout'))) {
    removeToken(KEY_AUTH)
    //ClearState
    dispatch(clearLoginState())
    navigate(ROUTER.HOME_PAGE)
    window.location.reload()
    // }
  }

  const handleSearchText = (e: string) => {
    setSearchText(e)
    onChangeInputSearch(e)
  }

  const onChangeInputSearch = useCallback(
    debounce((textSearch: string) => {
      onchangeParams('keyword', textSearch)
    }, 500),
    [],
  )
  // const openModalAuth = () => {
  //   const element = document.getElementById('modalAuthInput')
  //   element?.classList.add('show')
  //   element?.setAttribute('style', 'display:block')
  // }

  return (
    <div className='header' id='header'>
      {/* <div className='header__title'>
        <img src={icon_contact} alt={t('header.downloadAppAndGetGift')} />
        <h4>{t('header.downloadAppAndGetGift')}</h4>
      </div> */}
      <div className='header-top'>
        <div className='header-top__left'>
          <Link className='header-logo-2' to='/'>
            <img src={langItem.id === languageJA ? logo_headerJA : logo_header} alt={'logo Omipharma'} height={45} />
          </Link>
        </div>
        <div className='header-top__right'>
          {getLang() != languageJA && (
            <a href={DISTRIBUTOR_LINK} target='_blank' rel='noreferrer nofollow'>
              {t('header.distributor')}
            </a>
          )}
          {getLang() != languageJA && <Link to={ROUTER.DRUG_KNOWLEDGE_SCREEN}>{t('header.drugKnowledge')}</Link>}

          <a href={DIABETES_KNOWLEDGE_LINK} target='_blank' rel='noreferrer nofollow'>
            {t('header.diabetesKnowledge')}
          </a>
          <Link to={ROUTER.STORE_OMI_PHARMA}>
            <img src={ic_location_top} alt='icon location top' />
            {t('header.storeNumber')}
          </Link>
          <Link to={ROUTER.USER_NOTIFICATION_SCREEN}>
            {t('header.notification')}{' '}
            {unreadTotalNumber && accessToken && <span className='notify-unread'>({unreadTotalNumber})</span>}
          </Link>
          <div className='dropdown group-lang'>
            <a
              className='nav-link btn btn-secondary dropdown-toggle group-lang__btn-lang'
              href='#'
              id='dropdownLangButton'
              role='button'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              <img className='group-lang__flag-icon' src={langItem.icon} alt={langItem.alt} />
              <p className='group-lang__text'>{t(langItem.name)}</p>
              <img className='group-lang__dropdown-icon' src={ic_arrow_select_lc} alt='icon-arrow' />
            </a>
            <div className='group-lang__dropdown dropdown-menu' aria-labelledby='dropdownLangButton'>
              {languageList.map((item) => {
                return (
                  <a
                    key={item.id}
                    className='group-lang__lang_item dropdown-item'
                    onClick={() => handleChangeLang(item.id)}
                  >
                    <img src={item.icon} alt={item.alt} />
                    <div>
                      <p>{t(item.name)}</p>
                    </div>
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className='header__body'>
        <div className='header-container'>
          <div className='header-container__logo-section'>
            <Link className='header-logo' to='/' onClick={() => setSearchText('')}>
              <img src={langItem.id === languageJA ? logo_headerJA : logo_header} alt={'logo OmiCare'} height={45} />
            </Link>
            {renderMenuCategory()}
            {/* {renderMenuService()} */}
          </div>
          <div className='header-container__search-section'>
            <div className='header-search-box' ref={selectRef}>
              <input
                onFocus={onFocus}
                onChange={(e) => handleSearchText(e.target.value)}
                value={searchText || ''}
                type={'text'}
                className='input-box'
                placeholder={t('header.searchPlaceholder')}
                onKeyDown={handleKeyDown}
              />
              <a onClick={() => handleSearch()} className='icon-search'>
                <img src={ic_search} alt={'icon-search'} />
              </a>
            </div>
            <div className={`header-search-box-drop-down ${focus ? 'show' : ''}`}>
              {params?.keyword?.length > 0 ? (
                <div className={`header-search-box-drop-down__history`}>
                  {result?.length > 0 ? (
                    <>
                      {result.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div onClick={() => handleSearch(item.name)} className='search-result-item mb-2 mt-2'>
                              <BsSearch />
                              <span>{item.name}</span>
                            </div>
                            {index < result?.length - 1 && <div className='divider' />}
                          </React.Fragment>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      {isLoading ? (
                        <div className='spinner'></div>
                      ) : params?.keyword?.length > 0 ? (
                        t('homeSearch.productNotFound')
                      ) : (
                        t('homeSearch.pleaseEnterName')
                      )}
                    </>
                  )}
                </div>
              ) : (
                <>
                  <p className='header-search-box-drop-down__title'>{t('header.searchTitle')}</p>
                  <div className='header-search-box-drop-down__suggestion'>
                    {topSearchList.map((item, index) => {
                      return (
                        <a key={index} onClick={() => handleSearch(item)}>
                          {item}
                        </a>
                      )
                    })}
                  </div>
                  <div className='header-search-box-drop-down__banner'>
                    {banner.map((item, idx) => (
                      <a key={idx}>
                        <img src={item.image ?? ''} alt='banner' />
                      </a>
                    ))}
                  </div>
                  <div className='header-search-box-drop-down__divider' />

                  <p className='header-search-box-drop-down__title'>{t('header.regularSearch')}</p>
                  <div className='row header-search-box-drop-down__popular-product'>
                    {popularSearchList?.map((item, i) => {
                      return (
                        <div className='col-4' key={i} onClick={() => handleSearch(item.name)}>
                          <a>
                            <div className='header-search-box-drop-down__popular-product__item'>
                              <img src={item?.images?.[0]} alt={item?.name} />
                              <p>{item?.name}</p>
                            </div>
                          </a>
                        </div>
                      )
                    })}
                  </div>
                  <div className='header-search-box-drop-down__divider' />

                  <p className='header-search-box-drop-down__title'>{t('header.searchHighlight')}</p>
                  <div className='header-search-box-drop-down__popular-category'>
                    {featuredCategoriesList.map((item, i) => {
                      return (
                        <a
                          onClick={() => {
                            navigate(ROUTER.CATEGORY + '/' + item.slug)
                          }}
                          key={i}
                          className='header-search-box-drop-down__popular-category__item'
                        >
                          <img src={item.thumbnail} alt={item.name} />
                          <p>{item.name}</p>
                        </a>
                      )
                    })}
                  </div>
                </>
              )}
            </div>
            <div className='header-suggestion-search'>
              {topSearchList.map((item, index) => {
                return (
                  <a key={index} className='suggestion-item' onClick={() => handleSearch(item)}>
                    {item}
                  </a>
                )
              })}
            </div>
          </div>
          <div className='header-container__info-section'>
            <div className='header-container__info-section__user'>
              <div className='section-content-icon' onClick={auth?.id ? () => navigate(ROUTER.USER_SCREEN) : undefined}>
                <img className='header-icon' src={ic_user} alt={'icon-user'} />
              </div>
              {auth?.id ? (
                <>
                  <div
                    className='section-content-text section-content-text--normal'
                    onClick={() => navigate(ROUTER.USER_SCREEN)}
                  >
                    <p>
                      {t('header.account')}
                      <span>{auth.name}</span>
                    </p>
                  </div>
                  <div className='section-content-drop-list-wrap'>
                    <div className='section-content-drop-list'>
                      {listDropdownUser.map((item) => {
                        return (
                          <Link to={item.link} key={item.id}>
                            <div className='section-content-drop-list__item'>
                              <div className='section-content-drop-list__item__icon'>
                                <img src={item.icon} alt='icon' />
                              </div>
                              <p>{t(item.title)}</p>
                              {item.text && <span>{item.text}</span>}
                            </div>
                          </Link>
                        )
                      })}
                      <a onClick={logout}>
                        <div className='section-content-drop-list__item'>
                          <div className='section-content-drop-list__item__icon'>
                            <IoIosLogOut color='gray' size={25} />
                          </div>
                          <p>{t('logout')}</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='section-content-btn'>
                    <button id='openAuthModalBtn' data-bs-toggle='modal' data-bs-target='#modalAuthInput'>
                      {t('header.login')}
                    </button>
                    <button data-bs-toggle='modal' data-bs-target='#modalAuthInput'>
                      {t('header.register')}
                    </button>
                  </div>
                </>
              )}
            </div>
            <ModalAuth />
            <div className='header-container__info-section__order'>
              <div className='section-content-icon' onClick={() => navigate(ROUTER.ORDER_SCREEN)}>
                <img className='header-icon' src={ic_order} alt={'icon-order'} />
                {/* <div className='item-badge'>
                  <p>{orderNumber}</p>
                </div> */}
              </div>
              <div
                className='section-content-text section-content-text--normal'
                onClick={() => navigate(ROUTER.ORDER_SCREEN)}
              >
                {langItem.id === languageJA ? (
                  <p>
                    <span>{t('header.own')}</span>
                    {t('header.order')}
                  </p>
                ) : (
                  <p>
                    <span>{t('header.order')}</span>
                    {t('header.own')}
                  </p>
                )}
              </div>
              {/* <div className='section-content-order-drop-list-wrap'>
                <div className='section-content-order-drop-list'>
                  <div className='section-content-order-drop-list__search-box'>
                    <input type={'text'} className='input-box' placeholder={t('header.orderInput')} />
                    <div className='icon-search'>
                      <img src={ic_search} alt={'icon-search'} />
                    </div>
                  </div>
                  <table className='section-content-order-drop-list__table'>
                    <thead>
                      <tr>
                        <th>{t('header.orderCode')}</th>
                        <th>{t('header.value')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderList.map((order, index) => {
                        return (
                          <tr key={index} className='section-content-order-drop-list__table__item'>
                            <td>
                              <div className='d-flex justify-content-start'>
                                <Link to={ROUTER.ORDER_SCREEN + '/' + order.orderCode}>
                                  <div className='section-content-order-drop-list__table__item__order'>
                                    <img src={order.src} alt='icon-deliver' />
                                    <p>{order.orderCode}</p>
                                  </div>
                                </Link>
                              </div>
                            </td>
                            <td className='section-content-order-drop-list__table__item__total'>{order.price}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <div className='section-content-order-drop-list__btn'>
                    <Link to={ROUTER.ORDER_SCREEN}>{t('header.seeAll')}</Link>
                  </div>
                </div>
              </div> */}
            </div>
            <button
              className='header-container__info-section__cart'
              type='button'
              id='openModalFastAccessCartBtn'
              data-bs-toggle='modal'
              data-bs-target='#modalFastAccessCart'
            >
              <div id='cartHeader' className='section-content-icon'>
                <img src={ic_cart} alt={'icon-cart'} />
                <div className='item-badge'>
                  <p>{cartNumber}</p>
                </div>
              </div>
              <div className='section-content-text section-content-text--orange'>
                <p>
                  {t('header.cart')}
                  <span>&nbsp;</span>
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>
      <Menu />
    </div>
  )
}
