export function parseQuery(queryString: any) {
  const query: any = {}

  if (!queryString) {
    return query
  }

  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

export function buildQuery(data: any): string {
  if (typeof data !== 'object') {
    return ''
  }

  const queries: any = []
  Object.keys(data).map((k) => {
    queries.push(k + '=' + encodeURIComponent(data[k]))
  })
  return queries.join('&')
}

export function copy(src: any, target: any) {
  if (!target) {
    target = Array.isArray(src) ? [] : {}
  }

  Object.keys(src).map((k) => {
    target[k] = src[k]
  })

  return target
}

export function getUrlWithQuery(uri: string, query: any = {}): string {
  const [path, search] = uri.split('?')
  const current = parseQuery(search)
  copy(query, current)

  if (Object.keys(current).length) {
    return path + '?' + buildQuery(current)
  }

  return path
}

export const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
}

type CalculateParamType = {
  price: number
  discount_price?: number | null
  discount_percent?: number | null
}

export const calculatePrice = ({ price, discount_price, discount_percent }: CalculateParamType) => {
  if (discount_percent) {
    return Math.round((price * (100 - discount_percent)) / 100)
  }

  if (discount_price) {
    return price - discount_price
  }

  return price
}

export const calculateDiscountPercent = ({ price, discount_price, discount_percent }: CalculateParamType) => {
  if (discount_percent) {
    return discount_percent
  }
  if (discount_price) {
    return Math.round(100 - discount_price / price)
  }

  return 0
}

export function stringTruncate(str: string, length: number) {
  if (!str) {
    return ''
  }

  const dots = str.length > length ? '...' : ''
  return str.substring(0, length) + dots
}

export const debounce = (func: any, timeout = 300) => {
  let timer: any
  return (...args: any[]) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export const regex = {
  email: /@[^.]*\./,
  phoneRegExp:
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  phoneNumberRegExp: /^(0)+([0-9]{9})$/,
}

type InputFormatAddress = {
  addressDetail?: string
  wardName?: string
  districtName?: string
  provinceName?: string
}

export const formatAddress = ({ addressDetail, wardName, districtName, provinceName }: InputFormatAddress) => {
  const addressArray: Array<string> = []
  if (addressDetail) {
    addressArray.push(addressDetail)
  }
  if (wardName) {
    addressArray.push(wardName)
  }
  if (districtName) {
    addressArray.push(districtName)
  }
  if (provinceName) {
    addressArray.push(provinceName)
  }

  return addressArray.length > 0 ? addressArray.join(', ') : ''
}

export const calculatePageNumber = (
  total: number | string | undefined,
  pageSize: number | string | undefined,
): number => {
  if (!total || !pageSize) return 1
  // console.log(total, pageSize, Math.ceil(Number(total) / Number(pageSize)))

  return Math.ceil(Number(total) / Number(pageSize))
}

export function isEmpty(obj: any): boolean {
  if (!obj) {
    return true
  }
  for (const k in obj) {
    return false
  }
  return true
}

export const compareLaterDate = (dateOne: string, dateTwo?: string) => {
  const date = new Date()
  const date1 = new Date(dateOne).getTime()
  const date2 = dateTwo ? new Date(dateTwo).getTime() : date.getTime()
  return date1 > date2
}

export function convertToSlug(text: string) {
  if (!text) return text
  return removeVietnameseTones(text)
    .toLowerCase()
    .replace(/[^\w ]+/g, '-')
    .replace(/ +/g, '-')
}
export function removeVietnameseTones(str: string) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, '') // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ')
  str = str.trim()
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|,|\.|:|;|'|"|&|#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ')
  return str
}

export const timeArr = (currentHour: string, endHour: string, valueDate: Date) => {
  if (!currentHour || !endHour) {
    return []
  }
  const currentDate = new Date()
  const nowHour = currentDate?.getHours()
  const checkDate = currentDate?.toLocaleDateString() === valueDate?.toLocaleDateString()
  // Phân tích chuỗi currentHour thành giờ và phút
  const [currentHourStr, currentMinuteStr] = (currentHour || '00:00').split(':')
  const checkHour = currentHour && nowHour > Number(currentHourStr)
  const currentHourInt = currentHourStr ? parseInt(checkDate && checkHour ? nowHour.toString() : currentHourStr, 10) : 0
  const currentMinuteInt = currentMinuteStr ? parseInt(currentMinuteStr, 10) : 0

  // Phân tích chuỗi endHour thành giờ và phút
  const [endHourStr, endMinuteStr] = (endHour || '00:00').split(':')
  const endHourInt = endHourStr ? parseInt(endHourStr, 10) : 0
  const endMinuteInt = endMinuteStr ? parseInt(endMinuteStr, 10) : 0
  // Kiểm tra giá trị hợp lệ cho currentHour và endHour
  if (
    isNaN(currentHourInt) ||
    isNaN(currentMinuteInt) ||
    isNaN(endHourInt) ||
    isNaN(endMinuteInt) ||
    currentHourInt >= endHourInt ||
    (currentHourInt === endHourInt && currentMinuteInt >= endMinuteInt)
  ) {
    return []
  }

  // Tạo mảng để lưu trữ kết quả
  const arrTime = []

  // Lặp qua từng giờ từ currentHour đến endHour
  for (let hour = currentHourInt; hour < endHourInt; hour++) {
    // Tạo chuỗi nhãn cho mỗi khoảng thời gian
    const start = `${hour.toString().padStart(2, '0')}:${currentMinuteInt.toString().padStart(2, '0')}`
    const end = `${(hour + 1).toString().padStart(2, '0')}:${(endHourInt - hour === 1
      ? endMinuteInt
      : endMinuteInt >= currentMinuteInt
      ? currentMinuteInt
      : endMinuteInt + currentMinuteInt
    )
      .toString()
      .padStart(2, '0')}`
    const label = `${start} - ${end}`

    // Tạo đối tượng thời gian và thêm vào mảng kết quả
    const timeSlot = {
      id: hour,
      label: label,
      value: hour,
    }
    arrTime.push(timeSlot)
  }
  // Trả về mảng kết quả
  return arrTime
}

const generateId = (text: any) => {
  return text.toLowerCase().replace(/[^a-z0-9]+/g, '-')
}

export const extractHeadings = (content: any) => {
  const div = document.createElement('div')
  div.innerHTML = content

  const headings = div.querySelectorAll('h2, h3')
  const headingList = [] as any
  let currentH2 = null as any

  headings.forEach((heading, index) => {
    let id = heading.id
    if (!id) {
      id = generateId(heading.innerHTML) + '-' + index
      heading.id = id
    }
    const text = heading.textContent
    const level = heading.tagName.toLowerCase()

    if (level === 'h2') {
      currentH2 = { id, text, children: [] }
      headingList.push(currentH2)
    } else if (level === 'h3' && currentH2) {
      currentH2.children.push({ id, text })
    }
  })

  return headingList
}

export const addIdsToHeadings = (content: any) => {
  const div = document.createElement('div')
  div.innerHTML = content

  const headings = div.querySelectorAll('h2, h3')
  headings.forEach((heading, index) => {
    if (!heading.id) {
      heading.id = generateId(heading.innerHTML) + '-' + index
    }
  })

  const figures = div.querySelectorAll('figure')
  figures.forEach((figure) => {
    if (!figure.getAttribute('style')) {
      const imgWidth = figure.firstElementChild?.getAttribute('width')
      figure.setAttribute('style', 'width: ' + imgWidth + 'px')
    }
  })

  return div.innerHTML
}

export const handleClickChat = () => {
  if (window.$ChatBox && window.$ChatBox.show) {
    const tempChatBox = document.querySelector('.omi-chatbox') as any
    if (tempChatBox) {
      tempChatBox.classList.remove('low-z-index')
      if (tempChatBox.offsetHeight < 500) return window.$ChatBox.show()
      window.$ChatBox.hide()
    }
  } else {
    return
  }
}

export const isEmptyData = (data: any) => {
  if (data == null) return true // checks for undefined and null
  if (typeof data === 'string' || Array.isArray(data)) return data.length === 0
  if (typeof data === 'object') return Object.keys(data).length === 0
  return false // default to not empty if data type is unexpected
}
