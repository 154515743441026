import { GoogleOAuthProvider } from '@react-oauth/google'
import { Suspense, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import Layout from './Layout'
import { getInfoUser, getTemTokenAxios } from './app/features/auth'
import { useAppDispatch, useAppSelector } from './app/hooks'
import { changeLanguage, selectLanguage } from './app/slice'
import './assets/scss/app.scss'
import './assets/scss/sidebar.scss'
import './i18n/index'
import i18n from './i18n/index'
import RenderRoute from './router/RenderRoute'
import { getToken } from './helpers/cookieHelper'
import { KEY_AUTH, languageJA, languageVI } from './constant'
import { getTemToken } from './helpers/storage'
import ScrollToTop from './layouts/ScrollToTop'
import { Route, Routes, useLocation } from 'react-router-dom'
import { changeCampaignId, changeChannelId, changeContributorId, changeSlug } from './pages/checkoutScreen/store/slice'
import { ROUTER } from './router/config'
import Sitemap from './pages/sitemap'
import { Helmet } from 'react-helmet'

const SEVEN_DAYS_IN_MS = 7 * 24 * 60 * 60 * 1000
function App() {
  const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? 'GG'
  const dispatch = useAppDispatch()
  const language = useAppSelector(selectLanguage)
  const location = useLocation()
  const noLayoutPaths = [ROUTER.SITEMAP]
  const isNoLayout = noLayoutPaths.includes(location.pathname)
  const canonicalUrl = window.location.href

  useEffect(() => {
    const bodyElement = document.getElementsByTagName('body')[0]
    // const lang = localStorage.getItem('languageOmiPharma')
    if (language) {
      i18n.changeLanguage(language)
    }

    if (language === languageVI) {
      bodyElement.style.fontFamily = 'Arial,sans-serif,Helvetica'
    }
    if (language === languageJA) {
      bodyElement.style.fontFamily = 'Nunito,san-serif'
    }
  }, [language])

  useEffect(() => {
    const accessToken = getToken(KEY_AUTH)
    const tempToken = getTemToken()
    if (accessToken) {
      dispatch(getInfoUser())
    }
    if (!accessToken && !tempToken) {
      dispatch(getTemTokenAxios())
    }
    const lang =
      localStorage.getItem('languageOmiPharma') || (navigator.languages ? navigator.languages[0] : navigator.language)
    const defaultLang = lang.startsWith(languageJA) ? languageJA : languageVI
    i18n.changeLanguage(defaultLang)
    dispatch(changeLanguage(defaultLang))
  }, [])

  // const setItemWithExpiry = (key: any, value: any) => {
  //   const now = new Date();
  //   const item = {
  //     value,
  //     expiry: now.getTime() + SEVEN_DAYS_IN_MS,
  //   };
  //   localStorage.setItem(key, JSON.stringify(item));
  // };

  // const getItemWithExpiry = (key: any) => {
  //   const itemStr = localStorage.getItem(key);
  //   if (!itemStr) return null;

  //   const item = JSON.parse(itemStr);
  //   const now = new Date();

  //   // Kiểm tra nếu dữ liệu đã hết hạn
  //   if (now.getTime() > item.expiry) {
  //     localStorage.removeItem(key);
  //     return null;
  //   }

  //   return item.value;
  // };

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const contributorId = params.get('contributor');
  //   const channelId = params.get('channel');
  //   const campaignId = params.get('campaign');

  //   // Cập nhật localStorage và Redux với giá trị mới nhất
  //   if (contributorId) {
  //     setItemWithExpiry('contributorId', contributorId);
  //     dispatch(changeContributorId(contributorId));
  //   } else {
  //     // Nếu không có contributorId trong URL, lấy từ localStorage
  //     const storedContributorId = getItemWithExpiry('contributorId');
  //     if (storedContributorId) {
  //       dispatch(changeContributorId(storedContributorId));
  //     }
  //   }

  //   if (channelId) {
  //     setItemWithExpiry('channelId', channelId);
  //     dispatch(changeChannelId(channelId));
  //   } else {
  //     // Nếu không có channelId trong URL, lấy từ localStorage
  //     const storedChannelId = getItemWithExpiry('channelId');
  //     if (storedChannelId) {
  //       dispatch(changeChannelId(storedChannelId));
  //     }
  //   }

  //   if (campaignId) {
  //     setItemWithExpiry('campaignId', campaignId);
  //     dispatch(changeCampaignId(campaignId));
  //   } else {
  //     // Nếu không có campaignId trong URL, lấy từ localStorage
  //     const storedCampaignId = getItemWithExpiry('campaignId');
  //     if (storedCampaignId) {
  //       dispatch(changeCampaignId(storedCampaignId));
  //     }
  //   }
  // }, [location.search, dispatch]);

  const checkAndLoadFromStorage = (key: any) => {
    const itemStr = localStorage.getItem(key)
    if (!itemStr) return null // Không có key trong localStorage

    const item = JSON.parse(itemStr)
    const now = new Date()

    // Kiểm tra xem item đã hết hạn chưa
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key) // Xóa key nếu hết hạn
      return null
    }

    return item.value // Trả về giá trị nếu còn hợp lệ
  }

  useEffect(() => {
    // Kiểm tra và lấy dữ liệu từ localStorage nếu có
    const contributorId = checkAndLoadFromStorage('contributorId')
    const channelId = checkAndLoadFromStorage('channelId')
    const campaignId = checkAndLoadFromStorage('campaignId')
    const slug = checkAndLoadFromStorage('slug')
    if (contributorId) {
      dispatch(changeContributorId(contributorId))
    }
    if (channelId) {
      dispatch(changeChannelId(channelId))
    }
    if (campaignId) {
      dispatch(changeCampaignId(campaignId))
    }
    if (slug) {
      dispatch(changeSlug(slug))
    }
  }, [dispatch])

  return isNoLayout ? (
    <Routes>
      <Route path={ROUTER.SITEMAP} element={<Sitemap />}></Route>
    </Routes>
  ) : (
    <GoogleOAuthProvider clientId={client_id}>
      <ScrollToTop>
        <Layout>
          <Helmet>
            <link rel='canonical' href={canonicalUrl} />
          </Helmet>
          <RenderRoute />
          <ToastContainer />
        </Layout>
      </ScrollToTop>
    </GoogleOAuthProvider>
  )
}

export default App
