import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { AddressItemType } from '../../../models/address'
import { OrderType, PaymentMethodItem, ShippingMethod } from '../../../models/order'
import { StoreType } from '../../../models/types'

interface error {
  message: string
  type: string
}
interface errorsReceive {
  email: error
  userName: error
  phoneNumber: error
}
export const slice = createSlice({
  name: 'createOrderPage',
  initialState: {
    loading: false,
    paymentMethod: [] as PaymentMethodItem[],
    shippingMethod: [] as ShippingMethod[],
    order: undefined as OrderType | undefined | any,
    addressList: [] as AddressItemType[],
    selectedAddressId: 0,
    isUpdateAddress: false,
    isSelectAddress: true,
    selectedVoucherId: {} as any,
    confirmSuccess: false,

    //receiveGoodsAtThePharmacy
    type: 1,
    date: new Date(),
    time: '',
    storeSelected: {} as StoreType,
    userName: '',
    phoneNumber: '',
    email: '',
    errorsReceive: {} as errorsReceive,

    contributor: undefined as string | undefined,
    channelId: undefined as string | undefined,
    campaignId: undefined as string | undefined,
    slug: undefined as string | undefined,
    url: undefined as string | undefined,
  },
  reducers: {
    changeStateData: (state, action) => {
      state.paymentMethod = action.payload.payment_method || []
      state.shippingMethod = action.payload.shipping_method || []
      state.order = action.payload.order
    },
    changeAddressId: (state, action) => {
      state.selectedAddressId = action.payload
    },
    changeVoucherId: (state, action) => {
      state.selectedVoucherId = { ...state.selectedVoucherId, [action.payload.group_id]: action.payload.voucher_id }
    },
    changeIsUpdateAddress: (state, action) => {
      state.isUpdateAddress = action.payload
    },
    changeIsSelectAddress: (state, action) => {
      state.isSelectAddress = action.payload
    },
    changeConfirmSuccess: (state, action) => {
      state.confirmSuccess = action.payload
    },
    //receiveGoodsAtThePharmacy
    changeTypeReceive: (state, action) => {
      state.type = action.payload
    },
    changeUserReceive: (state, action) => {
      state.userName = action.payload.userName
      state.phoneNumber = action.payload.phoneNumber
      state.email = action.payload.email
    },
    changeTimeReceive: (state, action) => {
      state.time = action.payload
    },
    changeDateReceive: (state, action) => {
      state.date = action.payload
    },
    changeStoreReceive: (state, action) => {
      state.storeSelected = action.payload
    },
    changeErrorsReceive: (state, action) => {
      state.errorsReceive = action.payload
    },
    changeInfoUserReceive: (state, action) => {
      const { key, value } = action.payload as { key: 'email' | 'phoneNumber' | 'userName'; value: string }
      state[key] = value
    },
    changeRemoveVoucherId: (state, action) => {
      const { [action.payload.group_id]: _, ...newVoucher } = state.selectedVoucherId
      state.selectedVoucherId = newVoucher
    },
    changeContributorId: (state, action) => {
      state.contributor = action.payload
    },
    changeChannelId: (state, action) => {
      state.channelId = action.payload
    },
    changeCampaignId: (state, action) => {
      state.campaignId = action.payload
    },
    changeSlug: (state, action) => {
      state.slug = action.payload
    },
    changeUrl: (state, action) => {
      state.url = action.payload
    },
  },
})

export const selectPaymentMethod = (state: RootState) => state[slice.name].paymentMethod
export const selectOrder = (state: RootState) => state[slice.name].order
export const selectAddressList = (state: RootState) => state[slice.name].addressList
export const selectLoading = (state: RootState) => state[slice.name].loading
export const selectShippingMethod = (state: RootState) => state[slice.name].shippingMethod
export const selectSelectedAddressId = (state: RootState) => state[slice.name].selectedAddressId
export const selectSelectedVoucherId = (state: RootState) => state[slice.name].selectedVoucherId
export const selectIsUpdateAddress = (state: RootState) => state[slice.name].isUpdateAddress
export const selectIsSelectAddress = (state: RootState) => state[slice.name].isSelectAddress
export const selectConfirmSuccess = (state: RootState) => state[slice.name].confirmSuccess
export const selectReceiveGoodsAtThePharmacy = (state: RootState) => state[slice.name]
export const selectContributor = (state: RootState) => state[slice.name].contributor
export const selectChannel = (state: RootState) => state[slice.name].channelId
export const selectCampaign = (state: RootState) => state[slice.name].campaignId
export const selectSlug = (state: RootState) => state[slice.name].slug
export const selectUrl = (state: RootState) => state[slice.name].url

export const {
  changeStateData,
  changeAddressId,
  changeVoucherId,
  changeIsUpdateAddress,
  changeIsSelectAddress,
  changeConfirmSuccess,
  changeTypeReceive,
  changeUserReceive,
  changeDateReceive,
  changeTimeReceive,
  changeStoreReceive,
  changeErrorsReceive,
  changeInfoUserReceive,
  changeRemoveVoucherId,
  changeContributorId,
  changeChannelId,
  changeCampaignId,
  changeSlug,
  changeUrl,
} = slice.actions

export const CreateOrderReducer = slice.reducer
