import '../assets/scss/footer.scss'

import logo_fb from '../assets/images/logo_fb.png'
import logo_youtube from '../assets/images/logo_youtube.png'
import logo_zalo from '../assets/images/zalo.png'
import logo_cod from '../assets/icons/cod-payment.svg'
import logo_vnpay from '../assets/images/logo_vnpay.png'
import logo_omi_footer from '../assets/images/omicare.png'
import logo_omi_footer_ja from '../assets/images/omicare.png'
import ic_phone from '../assets/icons/ic_phone.svg'
import ic_email from '../assets/icons/ic_email.svg'
import logo_bct from '../assets/images/logo_bct.png'
import logo_dmca from '../assets/images/dmca.png'
import { FB_LINK, LanguageList, YOUTUBE_LINK_PAGE, ZALO_LINK, languageJA } from '../constant'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ROUTER } from '../router/config'
import { selectLanguage } from '../app/slice'
import { useAppSelector } from '../app/hooks'

export function Footer() {
  const { t } = useTranslation()
  const language = useAppSelector(selectLanguage)
  const languageList = LanguageList
  const langItem = languageList.find((item) => item.id === language) || languageList[0]
  return (
    <div className='footer' id={'footer'}>
      <div className='wrap-container footer-content'>
        <div className='footer-content__info footer-content__list-link'>
          <div className='row no-margin-hoz content-info'>
            <div className='col-sm-5' style={{ paddingRight: '50px' }}>
              <img
                src={langItem.id === languageJA ? logo_omi_footer_ja : logo_omi_footer}
                alt={'logo-omi'}
                width={'50%'}
              />
              <div className='no-margin-hoz content-copyright mt-3'>
                <div className='content-copyright__text'>
                  <h4>{t('footer.companyName')}</h4>
                  <p>{t('footer.businessRegistration')}</p>
                  <p>{t('footer.address')}</p>
                </div>
                <div className='content-copyright__logo'>
                  {/* <img src={logo_bct} alt='icon-copyright' /> */}
                  <img src={logo_dmca} alt='icon-copyright' style={{ height: '40px', verticalAlign: 'super' }} />
                </div>
              </div>
            </div>
            <div className='col-sm-2'>
              <h4 className='footer-list-title footer-list-title--bold'>{t('footer.omiCare')}</h4>
              <ul className='footer-list'>
                <li className='footer-list__link'>
                  <Link to='https://aboutus.omicare.vn/'>{t('footer.abouOmiCare')}</Link>
                </li>
                <li className='footer-list__link'>
                  <Link to={ROUTER.PRIVACY_POLICY_SCREEN}>{t('footer.privacyPolicy')}</Link>
                </li>
                <li className='footer-list__link'>
                  <Link to={ROUTER.TERMS_OF_SERVICE_SCREEN}>{t('footer.serviceTerm')}</Link>
                </li>
                <li className='footer-list__link'>
                  <Link to={ROUTER.NEWS_SCREEN}>{t('footer.companyNews')}</Link>
                </li>
              </ul>
            </div>
            <div className='col-sm-3'>
              <h4 className='footer-list-title footer-list-title--bold'>{t('footer.customerSupport')}</h4>
              <ul className='footer-list'>
                <li className='footer-list__link'>
                  <Link to={ROUTER.DELIVERY_POLICY_SCREEN}>{t('footer.deliveryPolicy')}</Link>
                </li>
                <li className='footer-list__link'>
                  <Link to={ROUTER.RETURN_POLICY_SCREEN}>{t('footer.exchangePolicy')}</Link>
                </li>
                <li className='footer-list__link'>
                  <Link to={ROUTER.ORDERING_GUIDE_SCREEN}>{t('footer.orderGuild')}</Link>
                </li>
              </ul>
              <h4 className='footer-list-title footer-list-title--bold'>{t('footer.paymentMethod')}</h4>
              <div className='footer-logo'>
                <img src={logo_cod} alt={'logo'} />
                <img src={logo_vnpay} alt={'logo'} />
              </div>
            </div>
            <div className='col-sm-2'>
              <h4 className='footer-list-title footer-list-title--bold'>{t('footer.connectUs')}</h4>
              <div className='footer-logo'>
                <a href={FB_LINK} target='_blank' rel='noreferrer nofollow'>
                  <img src={logo_fb} alt={'logo'} />
                </a>
                <a href={YOUTUBE_LINK_PAGE} target='_blank' rel='noreferrer nofollow'>
                  <img src={logo_youtube} alt={'logo'} />
                </a>
                <a href={ZALO_LINK} target='_blank' rel='noreferrer nofollow'>
                  <img src={logo_zalo} alt={'logo'} width={40} />
                </a>
              </div>
              <div className='footer-item-info'>
                <div className='icon-info'>
                  <img src={ic_phone} alt={'icon-info'} />
                </div>
                <p>
                  <span>{t('footer.PhoneNumber')}</span>
                </p>
              </div>
              <div className='footer-item-info'>
                <div className='icon-info'>
                  <img src={ic_email} alt={'icon-info'} />
                </div>
                <p>
                  <span>{t('footer.customerMail')}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
