export type RouterProps = {
  path: string
  element: any
  props?: any
  children?: RouterProps[]
}

export const ROUTER = {
  HOME_PAGE: '/',
  SITEMAP: '/sitemap.xml',
  PRODUCT_DETAIL: '/san-pham/',
  PRODUCT_DETAIL_SCREEN: '/san-pham/:slug',
  USER_SCREEN: '/user',

  ACCOUNT_SCREEN: '/user/account',
  USER_REVIEW_SCREEN: '/user/review',
  USER_NOTIFICATION_SCREEN: '/user/notify',
  USER_REGULAR_SCREEN: '/user/regular-schedule',
  PAYMENT_METHOD_SCREEN: '/user/payment-method',
  USER_PRODUCT_SCREEN: '/user/product',
  ORDER_SCREEN: '/user/order',
  ORDER_DETAIL_SCREEN: '/user/order/:id',
  USER_VOUCHER_SCREEN: '/user/voucher',
  USER_POINT_SCREEN: '/user/point',
  CREATE_ORDER: '/user/create-order',

  DRUG_SEARCH_SCREEN: '/tim-kiem/thuoc',
  DRUG_KNOWLEDGE_SCREEN: '/kien-thuc-ve-thuoc',
  DRUG_KNOWLEDGE_DETAIL_SCREEN: '/kien-thuc-ve-thuoc/chi-tiet/:slug',

  POLICY_SCREEN: '/policy',
  DELIVERY_POLICY_SCREEN: '/policy/chinh-sach-giao-hang',
  RETURN_POLICY_SCREEN: '/policy/chinh-sach-doi-tra',
  PRIVACY_POLICY_SCREEN: '/policy/chinh-sach-bao-mat',
  TERMS_OF_SERVICE_SCREEN: '/policy/dieu-khoan-dich-vu',
  ORDERING_GUIDE_SCREEN: '/policy/huong-dan-dat-hang',
  OMI_POINT_SCREEN: '/policy/chinh-sach-tich-diem-omiid',
  POLICY_TAB_SCREEN: '/policy/:slug',

  NEWS_SCREEN: '/tin-tuc',
  NEWS_DETAIL_SCREEN: '/tin-tuc/:slug',

  NEWS_REVIEW_SCREEN: '/news-review',
  NEWS_REVIEW_DETAIL_SCREEN: '/news-review/:slug',

  BRAND_SCREEN: '/thuong-hieu',
  BRAND_SCREEN_DETAIL: '/thuong-hieu/:brand',
  CATEGORY: '/danh-muc',
  CATEGORY_SCREEN: '/danh-muc/:slug',
  BRAN_SCREEN: '/branding',
  SEARCH_SCREEN: '/tim-kiem',

  PRESCRIPTION_SCREEN: '/mua-thuoc-theo-don',
  MANAGE_PRESCRIPTION_SCREEN: '/ca-nhan/danh-sach-don-thuoc',

  HEALTH: '/suc-khoe',
  HEALTH_POST: '/bai-viet',
  HEALTH_DETAIL: '/bai-viet/:slug',
  HEALTH_CATEGORY: '/suc-khoe/category',
  HEALTH_CATEGORY_DETAIL: '/suc-khoe/category/:slug',

  PROMOTION: '/promotion',

  STORE_OMI_PHARMA: '/store',
  ABOUT_OMI: '/gioi-thieu',

  PREVIEW_PRODUCT: '/preview/product/:slug',
  PREVIEW_NEWS: '/preview/news/:slug',
  PREVIEW_NEWS_REVIEW: '/preview/news-review/:slug',
  PREVIEW_HEALTH: '/preview/health/:slug',
  REGULAR_CONFIRM_SCREEN: '/regular-confirm',
}
