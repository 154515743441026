import $ from 'jquery'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
// import { toastSuccessErr } from '../../helpers/toast'
import LoadingOverlay from '../../components/LoadingOverlay'
import { ReactComponent as Times } from '../../assets/icons/fontAwaresome/times.svg'
import '../../assets/scss/fastCart.scss'
import VoucherCartItem from './VoucherCartItem'
import { useGetVoucherListQuery, useUpdateCartPromotionMutation } from '../../app/features/voucher'
import { idVoucherSelected } from './store/slice'
import { selectCartData } from '../fastCart/store/slice'
import { getCartDetail } from '../../app/features/cart/cart-api'
import { toastSuccessErr } from '../../helpers/toast'
import { changeSelectedVoucher } from './store/slice'

const VoucherSelect: React.FC = () => {
  const { t } = useTranslation()
  const cartData = useAppSelector(selectCartData)
  const { data: voucherData, refetch } = useGetVoucherListQuery({ cart_id: cartData?.id })
  const [updateCartPromotion, { isLoading }] = useUpdateCartPromotionMutation()
  const dispatch = useAppDispatch()
  const idVoucher = useAppSelector(idVoucherSelected)
  const [inputCode, setInputCode] = useState('')
  const [codeErr, setCodeErr] = useState(false)

  useEffect(() => {
    refetch()
  }, [cartData])

  const handleApplyVoucher = (code?: any) => {
    // if (!idVoucher) {
    //   toastSuccessErr({ type: 'error', message: t('userVoucher.selected_error') })
    //   return
    // }
    if (!isLoading) {
      if (code) {
        updateCartPromotion({
          promotion_code: code,
        }).then((response: any) => {
          if (response?.data?.code !== 200) {
            setCodeErr(response?.data?.message)
          } else {
            setCodeErr(false)
            setInputCode('')
            toastSuccessErr({ message: t('actionSuccess'), type: 'success' })
            dispatch(getCartDetail()).then((response: any) => {
              response?.payload?.data?.cart?.promotion.map((e: any) => {
                if (e.promotion_group.apply_other_group) {
                  const newVoucher = { group_id: e.promotion_group.id, voucher_id: e.id }
                  dispatch(changeSelectedVoucher(newVoucher))
                } else {
                  const newVoucher = { group_id: 0, voucher_id: e.id }
                  dispatch(changeSelectedVoucher(newVoucher))
                }
              })
            })
          }
        })
      } else {
        updateCartPromotion({
          promotions: idVoucher ?? 0,
        }).then(() => {
          setCodeErr(false)
          setInputCode('')
          dispatch(getCartDetail())
          $('#modalVoucher').hide()
          $('#cartHeader').click()
        })
      }
    }
  }

  return (
    <div className='modal-fast-cart'>
      <div
        className='modal fade'
        data-bs-backdrop='false'
        data-bs-keyboard='false'
        tabIndex={-1}
        id='modalVoucher'
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-xl'>
          <div className='modal-content modal-cart-content'>
            <div className='modal-cart-content__header'>
              <h3 className='text-center'>{t('fastCart.voucher')}</h3>
              <button type='button' data-bs-dismiss='modal' aria-label='Close' onClick={() => $('#cartHeader').click()}>
                <Times />
              </button>
            </div>
            <div className='modal-cart-content__info'>
              <div className='personal-voucher-search'>
                <div className='personal-voucher-search__input'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={t('userVoucher.placeholderInputCode')}
                    onChange={(e) => setInputCode(e.target.value)}
                    value={inputCode}
                  />
                  <button
                    type='button'
                    className='btn btn-danger btn-choose'
                    onClick={() => {
                      if (!inputCode) {
                        toastSuccessErr({ message: t('userVoucher.warningInputCode'), type: 'warning' })
                        return
                      }
                      handleApplyVoucher(inputCode)
                    }}
                  >
                    {t('userVoucher.buttonApply')}
                  </button>
                </div>
                {codeErr && <div className='personal-voucher-search__error text-danger'>{codeErr}</div>}
              </div>
              {voucherData &&
                Object.values(voucherData).map((item: any) => {
                  if (item.vouchers) {
                    return (
                      <>
                        <div className='personal-voucher-item font-weight-bold'>{item.name}</div>
                        {item.vouchers.map((promotion: any) => {
                          return <VoucherCartItem key={promotion.id} item={promotion} group={item} />
                        })}
                      </>
                    )
                  }
                  return <></>
                })}
            </div>
            <div className='modal-cart-content__footer'>
              <button className='btn btn-danger btn-choose' onClick={() => handleApplyVoucher()}>
                {t('userVoucher.buttonSubmit')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <LoadingOverlay isLoading={isLoading} />
    </div>
  )
}

export default VoucherSelect
